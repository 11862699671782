
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL, loadAds } from '@uinterview/uinterview'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
		VueperSlides,
		VueperSlide
	},
	data() {
		return {
			componentKey: 0,
			categCarousel: [],
			otherNews: [],
			newsList: [],
			jsonResp: []
		};
	},
	mounted() {
		loadAds()
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
				this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
				this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
				this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				if(this.categCarousel.length) {
					setTimeout(() => this.$refs.newsSlider.init(), 100);
				}
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
						this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
						this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						if(this.categCarousel.length) {
							setTimeout(() => this.$refs.newsSlider.init(), 100);
						}

					})
			}
		} else {
			console.log('noHazappData')
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
					if(this.categCarousel.length) {
						setTimeout(() => this.$refs.newsSlider.init(), 100);
					}
				})
		}
	},
	watch: {
		'$route' (to) {
			if(to.path == '/') {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							setTimeout(() => this.$refs.homeSlider.init(), 100);
						})
				}
			} else if(to.path.includes('/category/')){
				loadAds(this.$route.params.id)
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
							this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
							this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						})
				}
			} else if(to.path.includes('/page/')){
				// do nothing
			} else if(to.path.includes('/videos/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.videos[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.videos[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
						})
				}
			} else if(to.path.includes('/coronavirus/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.coronavirus[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.coronavirus[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
						})
				}
			} else if(to.path.includes('/ubio/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.ubio[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.ubio[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
								})
				}
			} else if(to.path.includes('/opinion/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.opinion[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.opinion[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
								})
				}
			} else if(to.path.includes('/congress/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.congress[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.congress[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
						})
				}
			} else {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.news[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.news).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.news[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.news).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
						})
				}
				// this.$refs.commentsButton.style.display = 'block'
				// this.$refs.blogCommentForm.style.display = 'none'
				// this.$refs.commentsContainer.innerHTML = ''
			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
		}
	}

};
